import { useCallback, useState } from 'react';
import logo from '../../resources/images/logo.png'
import { useParams } from 'react-router';
import { useDynamo } from '../../hooks/useDynamo';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import './Checkout.css'
import moment from 'moment-timezone';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';


function Checkout() {
  const params = useParams();
  const { item, isLoading, getDynamo: getItem } = useDynamo(`id=${params.id}&type=checkout`);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);

  const updateCheckoutAPI = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/dynamo`, {
        method: 'POST',
        body: JSON.stringify({ id: params.id, 'guest_checkout': true, 'guest_checkout_timestamp': moment() })
      })
      if (response.status !== 200) { setErrorDialogVisible(true) }

      return response.json()
    } catch (err) {
      console.log(err);
    }
  }, [params])

  const checkoutOnClick = useCallback(async () => {
    setUpdateLoading(true)
    await updateCheckoutAPI();
    await getItem();
    setUpdateLoading(false)
  }, [updateCheckoutAPI, getItem]);


  if (isLoading) return <div className="vertical-center"><ProgressSpinner /></div>

  if (item === null) return (
    <div>
      <div className="card flex-center">
        <Image className="logo flex-center" src={logo} alt="Image" width="50%" />
      </div>
      <div className="card error" >
        <Card title="Invalid Reservation">
          <p>We were unable to find your reservation. Please try again or contact us via call or text. </p>
          <p className="m-0">(909) 415-4611</p>
          <p className="m-0">info@wander-collective.com</p>
        </Card>
      </div>
    </div>
  )

  return (
    <div >
      <div className="card flex-center">
        <Image className="logo flex-center" src={logo} alt="Image" width="50%" />
      </div>
      <div className="card">
        <Card title="Reservation Details">
          <div className="details">Guest Name: <b>{item.guest_name}</b></div>
          <div className="details">Booking Reference Number: <b>{item.id}</b></div>
          <div className="details">Number of Guests: <b>{item.number_of_guests}</b></div>
          <div className="details">Cabin Number: <b>{item.unit}</b></div>
          <div className="details">Arrival Date: <b>{moment(item.check_in).format('M/DD/YYYY')}</b></div>
          <div className="details">Departure Date: <b>{moment(item.check_out).format('M/DD/YYYY')}</b></div>
          <div className="details">Check-in from: <b>{moment(item.check_in).format('h:mm A')}</b></div>
          <div className="details">Check-out by: <b>{moment(item.check_out).format('h:mm A')}</b></div>
        </Card>
      </div>
      <div className="card">
        <Card >
          <div className="details">
            <p>Thank you for staying with us!</p>

            <p> Before you leave, please ensure you have completed our checklist:
              <br></br>✅ Wash any dishes
              <br></br>✅ Turn off the lights
              <br></br>✅ Lock the door
            </p>

            <p>
              Take a moment to look around and ensure you have all of your personal belongings. Our housekeeping will take care of the rest!
            </p>

            <p>
              Please complete your checkout below.
            </p>
          </div>
        </Card>
      </div>
      <div className="card">
        <Card>
          {
            item.guest_checkout !== 'true' ?
              <Button label="COMPLETE CHECK-OUT" loading={updateLoading} onClick={checkoutOnClick} />
              : <Message severity="success" text="Checkout Complete" className='successMessage' />
          }
        </Card>
      </div>
      <Dialog header="Checkout Failed" visible={errorDialogVisible} style={{ width: '75vw' }} onHide={() => setErrorDialogVisible(false)}>
        <p>We were unable to succesfully complete your checkout. Please try again or contact us via call or text. </p>
        <p >(909) 415-4611</p>
      </Dialog>
    </div>
  );
}

export default Checkout;
