import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Checkout from './Views/Checkout/Checkout';
import Home from './Views/Home/Home';

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";
import 'primeflex/primeflex.css';
// import 'primeicons/primeicons.css';


export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/checkout/:id" element={<Checkout />} />
      </Routes>
    </div>
  );
}
