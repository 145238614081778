import { useCallback, useEffect, useState } from "react";

export const useDynamo = (parameters: string) => {
    const [isLoading, setIsLoading] = useState(false)
    const [item, setItem] = useState<any>({})

    const getDynamo = useCallback(async (showSpinner: boolean = true) => {
        if (showSpinner) setIsLoading(true)
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/dynamo?${parameters}`)
            const item = await response.json()
            if (showSpinner) setIsLoading(false)
            setItem(item)
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setItem(null);
        }
    }, [parameters])

    useEffect(() => {
        getDynamo()
    }, [getDynamo])

    return {
        isLoading,
        item: item,
        getDynamo: getDynamo
    }
};

